<template>
  <section :class="['no-results', variant]">
    <h1 v-if="headline" class="headline">{{ headline }}</h1>
    <h2 v-if="subline" class="subline">{{ subline }}</h2>

    <Illustration v-bind="{ alt }" class="image" :name="illustration" />

    <LcButton v-if="buttonText" @click="emit('cta-click')">
      {{ buttonText }}
    </LcButton>
  </section>
</template>

<script lang="ts" setup>
type NoResultsEmits = {
  (e: 'cta-click'): void
}

type NoResultsProps = {
  alt?: string
  buttonText?: string
  headline?: string
  illustration?: string
  subline?: string
  variant?: 'default' | 'outlined'
}

const emit = defineEmits<NoResultsEmits>()
withDefaults(defineProps<NoResultsProps>(), {
  alt: 'Warning sign.',
  illustration: 'no-result',
  variant: 'default',
})
</script>

<style lang="scss" scoped>
.no-results {
  display: flex;
  flex-direction: column;
  align-items: center;

  .headline,
  .subline {
    font-size: $font-size-h4;
  }

  .subline {
    margin-top: spacing('md');
  }

  .image {
    margin: spacing('lg') 0;
  }

  &.outlined {
    padding: spacing('lg') spacing('md');
    border: 0.0625rem color('secondary600') solid;
    border-radius: $border-radius-lg;

    .headline,
    .subline {
      margin: 0;
    }

    .image {
      margin: spacing('md') 0;
    }
  }
}
</style>
